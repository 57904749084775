import React, { useState } from "react";
import withUser, { WithUserProps } from "../../../hoc/withUser";

import Fieldset from "../../../components/core/Fieldset";
import FormLabel from "src/components/core/FormLabel";
import InputText from "src/components/core/InputText";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "../../../components/QuestionPage";
import { Trans } from "react-i18next";
import User from "src/models/User";
import { isFeatureEnabled } from "src/services/featureFlags";
import { useTranslation } from "../../../locales/i18n";

// TODO (PFMLPB-20787): update this export with all fields being edited in this file
export const fields = [
  "exemptionRequest.contact_first_name",
  "exemptionRequest.contact_last_name",
  "exemptionRequest.contact_title",
  "exemptionRequest.contact_phone_id",
  "exemptionRequest.contact_email_address",
];

function employerFeinBanner(user: User) {
  return (
    <div>
      <Trans
        i18nKey="shared.employerExemptions.employerEIN"
        values={{
          employer_fein: user.user_leave_administrators[0].employer_fein,
        }}
      />
      <br />
      <br />
    </div>
  );
}

export const EmployerExemptionsContactDetails = (props: WithUserProps) => {
  const { user } = props;
  const { t } = useTranslation();

  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  // TODO (PFMLPB-20787): update this function to use the Hook update function
  const handleSave = () => Promise.resolve();

  function createInputTextForField(
    fieldName: string,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) {
    const handleChange = (
      setter: React.Dispatch<React.SetStateAction<string>>
    ) => {
      return (event: React.FormEvent<HTMLInputElement>) =>
        setter(event.currentTarget.value);
    };
    return (
      <InputText
        key={fieldName}
        label={t(
          "pages.employersExemptionsContactDetails.fieldNames_contact_" +
            fieldName
        )}
        smallLabel={true}
        name={fieldName}
        onChange={handleChange(setter)}
      />
    );
  }

  // TODO (PFMLPB-20787): name and use these state variables in the handleSave method (currently not declaring because the linter will complain)
  const [, setFirstName] = useState("");
  const [, setLastName] = useState("");
  const [, setTitle] = useState("");
  const [, setPhoneNumber] = useState("");
  const [, setEmail] = useState("");

  const textInputs = [
    createInputTextForField("first_name", setFirstName),
    createInputTextForField("last_name", setLastName),
    createInputTextForField("title", setTitle),
    createInputTextForField("phone_id", setPhoneNumber),
    createInputTextForField("email_address", setEmail),
  ];

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }
  return (
    <React.Fragment>
      <QuestionPage
        title={t("pages.employersExemptionsContactDetails.title")}
        alertBanner={employerFeinBanner(user)}
        onSave={handleSave}
      >
        <Fieldset>
          <FormLabel
            component="legend"
            hint={
              <Trans
                i18nKey={
                  "pages.employersExemptionsContactDetails.sectionLabelHint"
                }
              />
            }
          >
            {t("pages.employersExemptionsContactDetails.sectionLabel")}
          </FormLabel>
          {textInputs}
        </Fieldset>
      </QuestionPage>
    </React.Fragment>
  );
};

// TODO (PFMLPB-20787): update this to wrap with employer exemptions hoc
export default withUser(EmployerExemptionsContactDetails);
